import { Divider, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import MailForm from 'components/contact/MailForm';

export default function Contact() {
  return (
    <Stack>
      <Stack
        spacing={2}
        sx={{
          p: 3,
          pt: 10,
          px: { xs: '5%', sm: '15%' },
        }}
      >
        <Grid container>
          <Grid xs={12} md={12} lg={12}>
            <Stack spacing={1} sx={{ mb: 3 }} alignItems={'center'}>
              <Typography variant="h3" sx={{ color: '#444' }}>
                Contactez notre équipe
              </Typography>
              <Typography
                variant="subtitle1"
                textAlign={'center'}
                sx={{ width: '80%', lineHeight: '1.4', color: '#444' }}
              >
                Vous avez des questions concernant un de nos services de Nettoyage? Nous sommes là pour vous aider.
                N'hésitez-pas à nous soumettre une demande à l'aide du formulaire ci-dessous ou de nous contacter par
                téléphone ou courriel.
              </Typography>
              <Divider sx={{ width: '50%', p: 1 }} />
            </Stack>
          </Grid>
          <Grid xs={12} md={6} lg={6} sx={{ p: 2 }}>
            <Typography variant="h6">Remplissez le formulaire</Typography>
            <Typography variant="body2">Nous vous répondrons dans les 48 heures ouvrables.</Typography>
            <MailForm />
          </Grid>
          <Grid xs={12} md={6} lg={6} sx={{ p: 2 }}>
            <Stack spacing={3}>
              <Stack>
                <Typography variant="h6">Appelez-nous</Typography>
                <Typography variant="body2">Vous pouvez nous joindre du lundi au vendredi, entre 9h et 17h.</Typography>
                <Typography variant="subtitle2">+1 (418) 832-7929</Typography>
              </Stack>
              <Stack>
                <Typography variant="h6">Envoyez-nous un courriel</Typography>
                <Typography variant="body2">
                  Nous vous répondrons dans un délais de 24h à 48h, les jours ouvrables.
                </Typography>
                <Typography variant="subtitle2">info@nettoyagenetstar.com</Typography>
              </Stack>
              <Stack>
                <Typography variant="h6">Venez-nous voir</Typography>
                <Typography variant="body2">
                  <strong>Sur rendez-vous seulement</strong>, vous pouvez venir nous voir à notre atelier pour nous
                  déposer vos items à nettoyer.
                </Typography>
                <Typography variant="subtitle2">4364 Avenue Saint-Augustin, Lévis, QC G6Z 8K1</Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}
