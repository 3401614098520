import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, Typography } from '@mui/joy';
import { Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from 'react-router-dom';

export default function Home() {
  const navigate = useNavigate();
  return (
    <Stack
      sx={{
        backgroundImage: 'url("/assets/stock_sofa.jpeg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        // p: 5,
        px: { xs: '5%', sm: '15%' },
        height: 'calc(100vh - 250px)',
        justifyContent: 'center',
      }}
    >
      <Grid container spacing={2}>
        <Grid xs={12} md={8} lg={8}>
          <Typography level="h1" textAlign="left">
            À votre service depuis <span style={{ color: '#0093ab' }}>20 ans.</span>
          </Typography>
        </Grid>
        <Grid xs={12} md={6} lg={6}>
          <Stack spacing={4}>
            <Stack spacing={2}>
              <Typography level="body2" sx={{ color: '#515960', fontSize: '18px' }}>
                Vous nous faites confiance depuis maintenant plus de 20 ans pour le nettoyage de vos{' '}
                <strong>tapis</strong>, de vos <strong>meubles rembourrés</strong> et de vos <strong>céramiques</strong>
                .
              </Typography>
              <Typography level="body2" sx={{ color: '#515960', fontSize: '18px' }}>
                Nettoyage Netstar, c'est une entreprise familiale fière de vous offrir un service de qualité.
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Stack spacing={1} justifyContent="center" alignItems="center">
                <Button variant="solid" endDecorator={<ArrowForwardIosIcon />} onClick={(e) => navigate('/contact')}>
                  Contactez-nous
                </Button>
                <Typography level="body3" sx={{ color: '#515960' }}>
                  Demandez un estimé <strong>gratuit</strong>!
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}
