import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Menu, MenuItem, Typography } from '@mui/joy';
import { AppBar, Button, IconButton, Stack, Tab, Tabs, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../svgs/logo_white.svg';

export default function Header() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navigate = useNavigate();

  const navigateFromMenu = (path: string) => {
    navigate(path);
    handleCloseNavMenu();
  };

  // Navigation tabs
  const location = window.location;
  const rootPath = location.pathname;

  const handleTabClick = (e: React.SyntheticEvent<Element, Event>, value: any) => {
    navigate(`/${value}`);
  };

  const tabs = [
    { label: 'Nettoyage de tapis', value: 'services/carpets' },
    { label: 'Nettoyage de céramiques', value: 'services/tile' },
    { label: 'Nettoyage de meubles', value: 'services/furniture' },
    { label: 'Nettoyage commercial', value: 'services/commercial' },
  ];

  const tabValues = tabs.map((tab) => tab.value);

  return (
    <AppBar
      position="static"
      sx={{
        background: '#003b77',
        boxShadow: 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 10,
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          py: mobile ? 1 : 2,
          px: mobile ? '5%' : '15%',
        }}
      >
        <img
          src={logo}
          alt="logo"
          style={{ width: mobile ? '100px' : '175px', cursor: 'pointer' }}
          onClick={() => (window.location.href = '/accueil')}
        />
        <Stack>
          <Stack direction="row" spacing={1} justifyContent="end">
            <Typography fontWeight={300} fontSize={12} textTransform="uppercase" sx={{ color: '#fff' }}>
              Téléphone
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} justifyContent="end" alignItems="center">
            <LocalPhoneIcon />
            <Typography fontWeight={600} fontSize={17} sx={{ color: '#fff' }}>
              +1 (418) 832-7929
            </Typography>
          </Stack>
        </Stack>
      </Toolbar>
      <Toolbar
        disableGutters
        sx={{
          justifyContent: 'space-between',
          background: '#001e3c',
          px: mobile ? '5%' : '15%',
          minHeight: '48px!important',
          alignItems: 'stretch',
        }}
      >
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            keepMounted
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
              width: '100%',
              borderRadius: 0,
            }}
          >
            {tabs.map((tab) => (
              <MenuItem key={tab.value} onClick={(e) => navigateFromMenu(tab.value)}>
                <Typography textAlign="center">{tab.label}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Tabs
          value={hasTabMatch(tabValues, rootPath)}
          onChange={handleTabClick}
          aria-label="navigation fivepd mtl"
          textColor="inherit"
          TabIndicatorProps={{ style: { background: '#fff' } }}
          sx={{ display: { xs: 'none', md: 'flex' } }}
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
        <Stack justifyContent="center" alignItems="center" sx={{ background: '#0068b1', width: '165px' }}>
          <Button
            sx={{
              color: '#fff',
              px: 3,
              // opacity: rootPath === 'contact' ? 1 : 0.6,
            }}
            onClick={() => navigate('/contact')}
          >
            Nous joindre
          </Button>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

const hasTabMatch = (tabValues: string[], path: string) => {
  for (const value of tabValues) {
    if (path.endsWith(value)) {
      return value;
    }
  }

  return false;
};
